<template>
  <div class="box">
    <!--  搜索  -->
    <div class="search-box flex_row m_b_10">
      <el-input class="m_r_30"
                v-model="courseListParams.name"
                placeholder="搜索课程"
                clearable
                @clear="clearInput_clear"
                @keydown.enter.native="searchBtn_click"></el-input>
      <el-button type="primary" @click="searchBtn_click">搜索</el-button>
    </div>

    <div class="m_tb_20" v-loading.fullscreen.lock="fullscreenLoading">
      <div class="selection_box bg_fff p_in_10 br_5">
        <el-radio-group class="m_tb_10"
                        v-for="(cataloguesItem,cataloguesIndex) of catalogues"
                        :key="cataloguesIndex"
                        v-model="selects[cataloguesIndex]"
                        size="small">
          <el-radio-button class="m_b_05"
                           v-for="(catalogueItem) of cataloguesItem"
                           :key="catalogueItem.id"
                           :label="catalogueItem.id"
                           @click.native="catalogue_click($event,catalogueItem,1)"
          >
            {{ catalogueItem.name }}
          </el-radio-button>
        </el-radio-group>
      </div>
      <div class="m_tb_20" v-loading="loading">
        <courseList :courseListData="courseData"/>
        <el-empty description="暂无数据" v-show="!courseData.length"></el-empty>
      </div>
    </div>
    <!--  分页  -->
    <div class="pagination">
      <el-pagination
          background
          :total="total"
          :page-size="12"
          :current-page="courseListParams.current"
          v-on:current-change="currentChange_click"
          hide-on-single-page
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {getResourcesCourse} from '@/api/search'
import courseList from "@/components/search/courseList";
import {getContents_api, getSubClassifyById_api} from "@/api/resourceCenter/region/search";

export default {
  name: 'searchResult',
  components: {courseList},
  data: () => {
    return {
      // 获取课程参数
      courseListParams: {
        isTelecast: 2,
        contentsId: '',
        userId: '',
        name: '',
        current: 1,
        size: 12,
      },
      // 数据总条数
      total: 0,
      // 加载
      loading: true,
      courseData: [],
      fullscreenLoading: false,
      catalogues: [],
      selects: []
    }
  },
  async mounted() {
    let query = this.$route.query
    await getSubClassifyById_api({id: '1554300188481306626', type: 1}).then(res => {
      let data = [{id: '1554300188481306626', name: '全部'}].concat(res.data.reverse())
      this.catalogues.push(data)
    })
    if (query.type === 'more') {
      this.selects[0] = this.catalogues[0][0].id
      await this.catalogue_click({target: {tagName: 'INPUT'}}, {id: this.catalogues[0][0].id}, 0)
    } else {
      this.selects[0] = query.classifyId
      await this.catalogue_click({target: {tagName: 'INPUT'}}, {id: query.classifyId}, 0)
      this.selects[1] = query.id
      await this.catalogue_click({target: {tagName: 'INPUT'}}, {id: query.id}, 1)
    }
  },
  methods: {
    // 点击搜索按钮
    searchBtn_click() {
      if (!this.courseListParams.name) return this.$message.warning('请输入搜索内容')
      this.courseListParams.classifyId ? this.getCourse(1) : this.getCourse()
    },

    // 获取目录
    async getCatalogue() {
      await getContents_api({}).then(res => {
        let data = [{id: '', name: '全部'}].concat(res.data)
        this.catalogues.push(data)
      })
    },
    // 获取子目录
    async getSubdirectories({id, type}) {
      if (id !== '1554300188481306626') {
        await getSubClassifyById_api({id, type}).then(res => {
          let data = res.data.reverse()
          this.catalogues.push(data)
        })
      }
      type === 1 ? this.getCourse(1) : this.getCourse()
    },
    // 获取资源
    getCourse(type) {
      this.loading = true
      this.courseListParams.classifyId = type === 1 ? this.selects.at(-1) : ''
      this.courseListParams.contentsId = type === 1 ? '' : this.selects.at(-1)
      getResourcesCourse(this.courseListParams).then(res => {
        this.total = res.data.total
        this.courseData = res.data.records
        this.loading = false
      })
    },
    // 点击目录
    async catalogue_click(e, catalogue, cataloguesIndex) {
      this.total = 0
      this.courseListParams.current = 1

      if (e.target.tagName === 'INPUT') {
        this.catalogues = this.catalogues.slice(0, cataloguesIndex + 1)
        let params = {id: catalogue.id, type: 1}
        if (catalogue.id === '1554300188481306626') {
          this.selects = ['1554300188481306626']
          params.type = 1
        } else {
          params.type = 2
          this.selects = this.selects.slice(0, cataloguesIndex + 1)
        }
        await this.getSubdirectories(params)
      }
    },
    // 分页
    currentChange_click(current) {
      this.courseListParams.current = current
      this.courseListParams.classifyId ? this.getCourse(1) : this.getCourse()
    },
    // 清空输入框
    clearInput_clear() {
      if (this.selects.length > 1) {
        this.getCourse(2)
      } else {
        this.getCourse(1)
      }
    }
  }
}

</script>
<style scoped>
.box {
  width: 120rem;
  margin: 1rem auto;
}

.search-box {
  width: 700px;
  margin: 0 auto;
}

.selection_box {
  width: 1200px;
}

::deep .el-radio-button__inner {
  border: 1px solid #f2f2f2;
  border-radius: 5px;
}

.el-radio-button {
  margin-right: 10px;
}

::deep .el-radio-button:last-child .el-radio-button__inner {
  border-radius: 5px;
}

::deep .el-radio-button:first-child .el-radio-button__inner {
  border-radius: 5px;
  border: 1px solid #f2f2f2;
}

.el-radio-group {
  display: block;
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px
}
</style>
